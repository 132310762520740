import React, {FC, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link, navigate} from 'gatsby';

import Section from '../../components/section';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import {DomainForm} from '../../components/form/domain-form';
import TechStackCategories from '../../components/tech-stack/techStackCategories';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../components/layout/layout';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import TechStackScanTool from '../../components/scan-tools/tech-stack';
import {Modal} from '../../components/modal';
import Button from '../../components/button';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import techStackImg from '../../img/home-tools/tech-stack.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const TechStackPage: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <Layout>
      <PageHero
        title="Website technology stack checker tool"
        subtitle={'Check out the technologies used on any website'}
      >
        <DomainForm value={url} onSubmit={u => navigate(`/stack-checker/${encodeURIComponent(u)}`)} />
      </PageHero>
      <Section>
        {isMobile ? (
          <>
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('TechStackChecker', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <TechStackScanTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <div className="row">
                      <div className="col-md-4" style={{marginBottom: '20px'}}>
                        <img src={techStackImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                      </div>
                      <div className="col-md-8">
                        <p>
                          Instantly discover the technology stack used by any prospect or competitor to understand how
                          their website works, which CMS or eCommerce platform they use, their communication tools of
                          choice as well as analytics, hosting, advertising or internet technology usage.
                        </p>
                      </div>

                      <div className="col-md-12">
                        <p>
                          Our free tool detects 60+stack Types for any website including databases, frameworks, coding
                          languages, CMS, marketing tools, e-commerce solutions and more.
                        </p>
                        <p>Sales and market share information</p>
                        <p>
                          Along with technologies, used for the scanned website, you can also see the most popular tech
                          stack list by types in the Top Charts section of our website which is updated several times
                          per hour depending on the number of new websites scanned and new stacks detected.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row" style={{marginTop: '30px'}}>
                <div className="col-md-6" style={{margin: 'auto'}}>
                  <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                </div>
                <div className="col-md-3">
                  <ToolTabs active="tech-stack" url={url} />
                </div>

                <div className="col-md-6">
                  <div className="flex-align-center">
                    <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                    <p>
                      Harness the internet as your own data source, build your own scraping bots and leverage ready made
                      automations to delegate time consuming tasks and scale your business.
                    </p>
                    <p>No coding or PhD in programming required.</p>
                    <div>
                      <Button
                        hexomaticCTA
                        className="monitor_btn"
                        onClick={() =>
                          (window.location.href = 'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                        }
                      >
                        Get started in minutes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr />
                  <div className="text-center">
                    <Translate name="TECH_STACK_CATEGORY_LIST_DESCRIPTION" />
                  </div>
                </div>
              </div>
              <TechStackCategories />
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="col-md-3">
              <ToolTabs active="tech-stack" url={url} />
            </div>
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('TechStackChecker', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <TechStackScanTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <div className="row">
                      <div className="col-md-4" style={{marginBottom: '20px'}}>
                        <img src={techStackImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                      </div>
                      <div className="col-md-8">
                        <p>
                          Instantly discover the technology stack used by any prospect or competitor to understand how
                          their website works, which CMS or eCommerce platform they use, their communication tools of
                          choice as well as analytics, hosting, advertising or internet technology usage.
                        </p>
                      </div>

                      <div className="col-md-12">
                        <p>
                          Our free tool detects 60+stack Types for any website including databases, frameworks, coding
                          languages, CMS, marketing tools, e-commerce solutions and more.
                        </p>
                        <p>Sales and market share information</p>
                        <p>
                          Along with technologies, used for the scanned website, you can also see the most popular tech
                          stack list by types in the Top Charts section of our website which is updated several times
                          per hour depending on the number of new websites scanned and new stacks detected.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row" style={{marginTop: '30px'}}>
                <div className="col-md-6" style={{margin: 'auto'}}>
                  <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                </div>
                <div className="col-md-6">
                  <div className="flex-align-center">
                    <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                    <p>
                      Harness the internet as your own data source, build your own scraping bots and leverage ready made
                      automations to delegate time consuming tasks and scale your business.
                    </p>
                    <p>No coding or PhD in programming required.</p>
                    <div>
                      <Button
                        hexomaticCTA
                        className="monitor_btn"
                        onClick={() =>
                          (window.location.href = 'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                        }
                      >
                        Get started in minutes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr />
                  <div className="text-center">
                    <Translate name="TECH_STACK_CATEGORY_LIST_DESCRIPTION" />
                  </div>
                </div>
              </div>
              <TechStackCategories />
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default TechStackPage;
